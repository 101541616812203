<template>
    <div class="contain">
        <div class="imgBox" id="数据中心">
            <!-- <div class="title">数据中心</div> -->
            <el-image class="img" :src="img"></el-image>
        </div>

        <div class="mainBox">
            <div class="tabsBox">
                <div class="tabsTitle"> 北京铁通IDC-T3数据中心 </div>
            </div>
            <div class="brief" id="机房基本信息">
                <div class="briefMain">
                    <div class="map">
                        <el-image class="img" :src="img01"></el-image>
                    </div>
                    <div class="main">
                        <div class="mainTop">
                            <el-image class="iconBlue" :src="iconBlue"></el-image>
                            <div class="title"> 机房基本信息 </div>
                        </div>
                        <div class="textBox">
                            <p class="pBox"> 北京铁通IDC-T3数据中心位于北京经济技术开发区兴盛街15号，独门独户厂院，紧邻南五环、G2高速，距地铁亦庄线荣京东街站仅1000米，交通便利，35km半径覆盖北京所有机场及高铁枢纽。 </p>
                            <p class="pBox"> 院内空间宽敞，占地面积超过8100㎡，其中机房楼建筑面积5800㎡，地上两层建筑，机房区面积近3000㎡，共有9个独立的数据机房，容纳封闭式标准机柜1187个，可为12000台以上服务器设备提供主机托管服务。 </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="buildBox">
                <!-- <div class="bulidTitle"> 机房建设等级：“GB50174A级机房、TIA-942T3+” </div> -->
                <el-image class="img" :src="build"></el-image>
            </div>

            <div class="brief" id="机房电力情况">
                <div class="innerMain">
                    <el-image class="img" :src="room"></el-image>
                    <div class="innerBox innerLeft">
                        <div class="innerTop">
                            <el-image class="iconBlue" :src="iconBlue"></el-image>
                            <div class="title"> 机房电力情况 </div>
                        </div>
                        <div class="innerTxt">
                            <p class="pBox pBottom"> 市电供电系统为10kV双环网引入，两路电源互为备用 </p>
                            <p class="pBox pBottom"> 多台2350kVA高压柴油发电机组作为后备电源 </p>
                            <p class="pBox pBottom"> 8台2000kVA变压器按“1+1”互为备用方式运行 </p>
                            <p class="pBox pBottom"> 低压配电系统分组联络、稳定承载 </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="brief" id="不间断电源系统">
                <div class="innerMain">
                    <div class="innerBox">
                        <div class="innerTop">
                            <el-image class="iconBlue" :src="iconBlue"></el-image>
                            <div class="title"> 不间断电源系统 </div>
                        </div>
                        <div class="innerTxt">
                            <p class="pBox pBottom"> 模块化高频UPS主机12套，单系统容量400kW </p>
                            <p class="pBox pBottom"> 单系统配备16个功率模块，模块单独故障可支持在线热插拔 </p>
                            <p class="pBox pBottom"> 配备高功率铅酸蓄电池组，单系统满载后备时间15分钟以上 </p>
                            <p class="pBox pBottom"> 采用独立双总线配电方式为终端IT设备供电 </p>
                        </div>
                    </div>
                    <el-image class="img innerLeft" :src="continuous"></el-image>
                </div>
            </div>

            <div class="brief" id="数据机房情况">
                <div class="innerMain">
                    <div class="innerBox">
                        <div class="innerTop">
                            <el-image class="iconBlue" :src="iconBlue"></el-image>
                            <div class="title"> 数据机房情况 </div>
                        </div>
                        <div class="innerTxt">
                            <p class="pBox pMin"> 机房抗震设防烈度为8度 </p>
                            <p class="pBox pMin"> 楼板承重1400kg/㎡ </p>
                            <p class="pBox pMin"> 架空地板+封闭冷通道送风系统 </p>
                            <p class="pBox pMin"> 采用600×1100×2200标准机柜 </p>
                            <p class="pBox pMin"> 单柜功率支持：2.86-5kW </p>
                            <p class="pBox pMin"> 标配PDU规格：32A输入，13×10A+2×16A输出 </p>
                            <p class="pBox pMin"> 支持机柜按需定制 </p>
                            <p class="pBox pMin"> 地板下静压箱送风方式+封闭冷池系统，架空地板高度660mm </p>
                        </div>
                    </div>
                    <el-image class="img innerLeft" :src="situation"></el-image>
                </div>
            </div>

            <div class="brief" id="空调与制冷">
                <div class="innerMain">
                    <el-image class="img" :src="refrigeration"></el-image>
                    <div class="innerBox innerLeft">
                        <div class="innerTop">
                            <el-image class="iconBlue" :src="iconBlue"></el-image>
                            <div class="title"> 空调与制冷 </div>
                        </div>
                        <div class="innerTxt">
                            <p class="pBox pBottom"> 机房采用风冷式精密空调，并配置了制冷剂泵循环系统 </p>
                            <p class="pBox pBottom"> 春秋冬季可有效利用室外自然冷源 </p>
                            <p class="pBox pBottom"> 机房区空调按满5备1方式设置备用机，冷量冗余超过40% </p>
                            <p class="pBox pBottom"> 统一采用湿膜加湿系统，精密空调去除加湿功能 </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="brief" id="消防设施">
                <div class="innerMain">
                    <el-image class="img" :src="facilities"></el-image>
                    <div class="innerBox innerLeft">
                        <div class="innerTop">
                            <el-image class="iconBlue" :src="iconBlue"></el-image>
                            <div class="title"> 消防设施 </div>
                        </div>
                        <div class="innerTxt">
                            <p class="pBox pBottom"> 机房设置火灾自动报警系统、七氟丙烷气体自动灭火系统 </p>
                            <p class="pBox pBottom"> 每层为独立的防火分区，每个防火分区按机房划分为不同气灭分区</p>
                            <p class="pBox pBottom"> 共配备120升气体钢瓶39个，喷射后15秒内惰性气体完全充满整个防火分区 </p>
                            <p class="pBox pBottom"> 各气灭分区内的气体灭火系统独立启动 </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="brief" id="动环监控系统">
                <div class="surplus maxMag">
                    <div class="surplusTop">
                        <el-image class="iconBlue" :src="iconBlue"></el-image>
                        <div class="title"> 动环监控系统 </div>
                    </div>
                    <div class="surplusInner">
                        <div class="surplusMain">
                            <p class="pBox pMarT">
                                完备的动环监控系统实现对机房内所有供配电设备、机房环境以及空调设备的监控维护人员可第一时间感知和定位故障节点，快速做出反应闭路电视监控系统对机房内各 </p>
                            <p class="pBox pMarT"> 机柜列、机房出入口、公共区域等位置实现7×24小时不间断的正常秩序管理 </p>
                        </div>
                        <div class="imgDiv">
                            <div v-for="item in monitorList" :key="item.id">
                                <el-image class="img" :src="item.img"></el-image>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="brief" id="网络传输资源">
                <div class="surplus minMag">
                    <div class="surplusTop">
                        <el-image class="iconBlue" :src="iconBlue"></el-image>
                        <div class="title"> 网络传输资源 </div>
                    </div>
                    <div class="surplusInner">
                        <div class="surplusMain">
                            <p class="pBox pMarT">
                                采用顶级网络设备NE5000E为核心的高效、全冗余网络平台100G入中国CTTNET、CMNET骨干网多条万兆接入CNCNet、CTNet和CERNET骨干网形成与中国联通、
                            </p>
                            <p class="pBox pMarT"> 中国电信和中国教育网三网穿透的独特优势 </p>
                        </div>
                        <div class="imgDiv">
                            <el-image class="img" :src="resources"></el-image>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Datacenter",
    data() {
        return {
            iconBlue: require("./images/iconBlue.png"),
            img: require("./images/about01.png"),
            img01: require("./images/01.png"),
            build: require("./images/build.png"),
            room: require("./images/room.png"),
            power: require("./images/power.png"),
            continuous: require("./images/continuous.png"),
            situation: require("./images/situation.png"),
            refrigeration: require("./images/refrigeration.png"),
            facilities: require("./images/facilities.png"),
            monitorList: [
                {
                    img: require("./images/monitor01.png"),
                    id: '01',
                },
                {
                    img: require("./images/monitor02.png"),
                    id: '02',
                },
                {
                    img: require("./images/monitor03.png"),
                    id: '03',
                }
            ],
            resources: require("./images/resources.jpg"),
        }
    }
};
</script>

<style lang='less' scoped>
.imgBox {
    width: 100%;
    // min-width: 1200px;
    height: 610px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
        color: #fff;
        z-index: 10;
        position: absolute;
        font-size: 64px;
    }

    .img {
        width: 100%;
        height: 100%;
    }
}

.mainBox {
    .tabsBox {
        width: 100%;
        border-bottom: 1px solid #bbb;
        padding: 20px 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        .tabsTitle {
            font-size: 18px;
            font-weight: 600;
            padding-bottom: 6px;
            border-bottom: 4px solid #E94135;
        }
    }

    .brief {
        width: 1200px;
        margin: 0 auto;

        .briefMain {
            display: flex;
            align-items: center;
            margin-top: 80px;
            position: relative;

            .map {
                width: 814px;
                height: 469px;

                .img {
                    width: 100%;
                    height: 100%;
                }
            }

            .main {
                width: 386px;
                position: absolute;
                right: 70px;

                .mainTop {
                    display: flex;
                    align-items: center;
                    margin-bottom: 40px;

                    .title {
                        margin-left: 10px;
                        color: #000;
                        font-size: 24px;
                    }
                }

                .textBox {
                    line-height: 30px;

                    .pBox {
                        font-size: 16px;
                        color: #001c41;
                    }
                }
            }
        }

        .innerMain {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-bottom: 80px;

            .innerBox {
                 min-width: 7.5em;
                .innerTop {
                    display: flex;
                    align-items: center;
                    margin-bottom: 40px;

                    .title {
                        margin-left: 10px;
                        color: #000;
                        font-size: 24px;
                    }
                }

                .innerTxt {
                    .pBox {
                        font-size: 16px;
                        color: #001c41;
                    }

                    .pBottom {
                        margin-bottom: 40px;
                    }

                    .pMin {
                        margin-bottom: 20px;
                    }
                }
            }

            .innerLeft {
                margin-left: 40px;
            }
        }

        .surplus {
            .surplusTop {
                display: flex;
                align-items: center;

                .title {
                    margin-left: 10px;
                    color: #000;
                    font-size: 24px;
                }
            }

            .surplusInner {
                .surplusMain {
                    margin-top: 40px;

                    .pBox {
                        font-size: 16px;
                        color: #001c41;
                    }

                    .pMarT {
                        margin-bottom: 40px;
                    }
                }

                .imgDiv {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                }

            }
        }

        .maxMag {
            margin-bottom: 80px;
        }

        .minMag {
            margin-bottom: 80px;
        }
    }

    .buildBox {
        width: 100%;
        // min-width: 1200px;
        height: 136px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 32px 0 80px;

        .img {
            width: 100%;
        }

        .bulidTitle {
            position: absolute;
            z-index: 10;
            color: #2c67af;
            font-size: 48px;
            font-weight: 700;
        }
    }
}

// .introduce 
.introduce /deep/ .el-carousel__button {
    // 指⽰器按钮
    background-color: rgba(0, 0, 0, 0.2);
}

/deep/ .introImgBox .el-carousel__button {
    // 指⽰器按钮
    width: 20px;
    background-color: rgba(0, 0, 0, 0.2);
}

/deep/ .is-active .el-carousel__button {
    // 指⽰器激活按钮
    background: #3f8ec8;
}
/* 手机端 */
@media (max-width: 767px) {
  .iconBlue {
    width: 1em;
    height:1em ;
  }
}

/* 平板端 */
@media (min-width: 768px) and (max-width: 991px) {
  .iconBlue {
    width: 1.55em;
    height:1.55em ;
  }
}

/* 桌面端 */
@media (min-width: 992px) {
  // .iconBlue {
  //   width: 22px;
  // }
}
</style>