<template>
  <div class="contact">
    <div class="imgBox">
      <el-image class="img" :src="img"></el-image>
    </div>
    <div class="main">
      <div>
        <div class="contactTitle">联系我们</div>
        <div class="mode">
          <p>联系方式：010-87227768</p>
          <p>企业邮箱：18811049707@139.com</p>
          <p>地址：北京市北京经济技术开发区地盛北街1号院31号楼2单元5层</p>
          <p>邮政编码：100176</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
  data() {
    return {
      img: require("./images/contact01.jpg"),
    };
  },
};
</script>

<style lang='less' scoped>
.imgBox {
  width: 100%;
  height: 400px;

  .img {
    width: 100%;
    height: 100%;
  }
}

.main {
  width: 1200px;
  min-height: 300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .contactTitle {
    text-align: center;
    padding: 20px 0;
    font-size: 24px;
  }

  .mode {
    p {
      font-size: 16px;
      margin-bottom: 6px;
    }
  }
}
/* 手机端 */
@media (max-width: 767px) {
  .iconBlue {
    width: 1em;
    height:1em ;
  }
}

/* 平板端 */
@media (min-width: 768px) and (max-width: 991px) {
  .iconBlue {
    width: 1.55em;
    height:1.55em ;
  }
}

/* 桌面端 */
@media (min-width: 992px) {
  // .iconBlue {
  //   width: 22px;
  // }
}
</style>